<template>
    <BoxLayout>
        Page not found
    </BoxLayout>
</template>

<script>
    import BoxLayout from '@/components/BoxLayout.vue';

    export default {
        components: {            
            BoxLayout,
        },
    }
</script>